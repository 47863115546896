import React from 'react';
import { Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ProfilVerwalten() {

  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Button
        onClick={() => navigate(`/profile`)}
        variant="contained"
        fullWidth
        style={{ marginBottom: '10px', minHeight: '48px' }} // Hier wird die minHeight des Accordion verwendet
      >
        Profil verwalten
      </Button>
    </Container>
  );
}

export default ProfilVerwalten;
