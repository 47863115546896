import React from 'react';
import { Typography } from '@mui/material';
import './UserPage.css'; // Falls du spezifische Styles für die User-Seite brauchst
import KurseListe from './reusable_modules/KurseListe';
import ProfilVerwalten from './reusable_modules/ProfilVerwalten';

function UserPage() {
  return (
    <div className="User-container">
      <div className="User-header" style={{ padding: '64px 0', textAlign: 'center' }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Persönlicher Bereich
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Verwalten Sie Ihre Kurse und Inhalte.
        </Typography>
      </div>

      <div className="User-list">
        <KurseListe />
        <ProfilVerwalten />
        
      </div>
    </div>
  );
}

export default UserPage;
