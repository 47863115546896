import React, { useState, useContext, useEffect } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../AuthContext';

function KurseListe() {
  const [kurse, setKurse] = useState([]);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEigeneKurse(); // Kurse laden, wenn die Komponente gerendert wird
  }, []);

  const fetchEigeneKurse = () => {
    axios.get('/api/getEigeneKurse', {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      setKurse(response.data); // Kurse für den Benutzer setzen
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Kurse:', error);
    });
  };

  const handleKursClick = (kursId) => {
    navigate(`/kursuebersicht/${kursId}`);
  };

  return (
    <Container maxWidth="sm">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Kurse anzeigen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {kurse.length > 0 ? (
            kurse.map((kurs) => (
              <Button
                key={kurs.kurs_id}
                onClick={() => handleKursClick(kurs.kurs_id)}
                variant="contained"
                fullWidth
                style={{ marginBottom: '10px', height: '48px', backgroundColor: 'var(--accent-color)',  // Hintergrundfarbe
                color: 'var(--text-color)',              // Schriftfarbe
                fontWeight: 'bold'    }} // Feste Höhe setzen
              >
                {kurs.name}
              </Button>
            ))
          ) : (
            <Typography variant="body1">Es sind derzeit keine Kurse verfügbar.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default KurseListe;
