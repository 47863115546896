import React, { useState, useContext, useEffect } from 'react';
import { Container, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';

function KapitelFormular() {
  const { kapitelId } = useParams();  // kapitelId aus den Routen-Parametern holen
  const [kapitelName, setKapitelName] = useState('');
  const [inhalt, setInhalt] = useState(''); // Der Textinhalt des Kapitels
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (kapitelId) {
      // Kapitel und KapitelText abrufen
      axios.get(`/api/singleKapitel/${kapitelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        const { name, text } = response.data;
        setKapitelName(name);  // Setze den Kapitelnamen
        setInhalt(text || ''); // Setze den Textinhalt
        setLoading(false);
      })
      .catch(error => {
        console.error('Fehler beim Abrufen des Kapitels:', error);
        setLoading(false);
      });
    }
  }, [kapitelId, token]);
  
  

  const handleEditorChange = (content) => {
    setInhalt(content); // Inhalt des Editors setzen
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(`/api/updateKapitelText/${kapitelId}`, {
        text: inhalt, // Textinhalt aus dem Editor
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage(response.data.message);
      setOpenDialog(true);
    } catch (error) {
      console.error('Fehler beim Speichern des Kapitels:', error);
      setMessage('Ein Fehler ist aufgetreten');
      setOpenDialog(true);
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px', fontWeight: 'bold' }}>
        {kapitelName} bearbeiten
      </Typography>


      <Editor
  tinymceScriptSrc='/tinymce/tinymce.min.js'
  value={inhalt}
  init={{
    height: 1000,
    menubar: false,
    license_key: 'gpl',
    plugins: [
      'link', 'lists', 'image', 'media', 'quickbars', 'advlist' // Quickbars Plugin hinzugefügt
    ], 
    toolbar: 
      'undo redo | formatselect | bold italic fontfamily fontsize forecolor| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image media',
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote', // Schnellzugriff für Text
    quickbars_insert_toolbar: 'quickimage quicktable | hr', // Schnellzugriff für Einfügen
    quickbars_image_toolbar: 'alignleft aligncenter alignright | imageoptions', // Schnellzugriff für Bildbearbeitung
    images_upload_url: '/api/upload-image',
    automatic_uploads: true,

    // Bild-Upload-Handler
    images_upload_handler: (blobInfo, success, failureCallback) => {
      const formData = new FormData();
      formData.append('file', blobInfo.blob());
      axios.post('/api/upload-image', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        success(response.data.location);
      })
      .catch(() => {
        failureCallback('Bild konnte nicht hochgeladen werden.');
      });
    },

    // Video- und Audio-Upload-Handler
    file_picker_types: 'image media',
    file_picker_callback: function (callback, value, meta) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      if (meta.filetype === 'image') {
        input.setAttribute('accept', 'image/*');
      } else {
        input.setAttribute('accept', 'audio/*,video/*');
      }
      input.onchange = function () {
        const file = this.files[0];
        const formData = new FormData();
        formData.append('file', file);

        axios.post(
          file.type.startsWith('audio') ? '/api/upload-audio' : '/api/upload-video',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(response => {
          callback(response.data.location, { title: file.name });
        })
        .catch(() => {
          alert('Datei konnte nicht hochgeladen werden.');
        });
      };
      input.click();
    },

    media_live_embeds: true,
    media_dimensions: false,
    media_alt_source: false,
    media_poster: true,
  }}
  onEditorChange={handleEditorChange}
/>



      <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={handleSubmit} variant="contained" color="primary" style={{ marginRight: '20px' }}>
          Speichern
        </Button>
        <Button onClick={() => navigate(`/kapitel/${kapitelId}`)} variant="contained" color="secondary">
          Kapitel ansehen
        </Button>
      </div>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Info</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default KapitelFormular;
