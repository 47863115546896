import React, { useState, useContext } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AuthContext from '../AuthContext'; 

function KursAnlegen() {
  const [kursName, setKursName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const { token } = useContext(AuthContext); // Zugriff auf das JWT-Token

  const handleSubmit = async () => {
    try {
      const response = await axios.post('/api/createKurs', { name: kursName }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessage(`Kurs "${response.data.kurs.name}" wurde erfolgreich erstellt.`);
      setOpenDialog(true);
      setKursName(''); // Reset des Formulars nach erfolgreicher Erstellung
    } catch (error) {
      setMessage(error.response?.data?.message || 'Ein Fehler ist aufgetreten.');
      setOpenDialog(true);
    }
  };

  return (
    <Container maxWidth="sm">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">Kurs erstellen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="Kursname"
            value={kursName}
            onChange={(e) => setKursName(e.target.value)}
            fullWidth
            margin="normal"
            style={{ 
              backgroundColor: 'var(--custom-white)', 
              color: 'var(--text-color)', 
            }}
          />
          <Button variant="contained" onClick={handleSubmit} fullWidth style={{ marginBottom: '10px',
                height: '48px',
                backgroundColor: 'var(--accent-color)',  // Hintergrundfarbe
                color: 'var(--text-color)',              // Schriftfarbe
                fontWeight: 'bold'    }}>
            Kurs erstellen
          </Button>
        </AccordionDetails>
      </Accordion>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Info</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default KursAnlegen;
