import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './Login.css';
import axios from 'axios';

function Login() {
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://doctrina-mobilis.de/api/login', {
        username,
        password
      }, { withCredentials: true }); 

      if (response.status === 200) {
        const userResponse = await axios.get('https://doctrina-mobilis.de/api/user', {
          withCredentials: true
        });

        if (userResponse.status === 200) {
          login(userResponse.data);
          setMessage('Login successful');
          navigate('/');
        } else {
          setMessage('Failed to fetch user data after login');
        }
      } else {
        setMessage(response.data.message || 'Invalid login credentials');
      }
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message || 'Invalid login credentials');
      } else {
        setMessage('An error occurred while processing your request');
      }
    }
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <div className="login">
      <form onSubmit={handleSubmit}>
        <div>
          <TextField
            label="Benutzername"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            fullWidth
            className="input-field"
            style={{
              backgroundColor: 'var(--custom-white)', 
              color: 'var(--text-color)'
            }}
          />
        </div>
        <div>
          <TextField
            label="Passwort"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            className="input-field"
            style={{
              backgroundColor: 'var(--custom-white)', 
              color: 'var(--text-color)'
            }}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          className="login-button"
        >
          Login
        </Button>
      </form>
      <Button onClick={handleRegister} className="register-link">
        Registrieren
      </Button>
      {message && <p>{message}</p>}
    </div>
  );
}

export default Login;
