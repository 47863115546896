import React, { useState, useEffect, useContext } from 'react';
import './Termine.css';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/de'; // Für deutsche Wochentage
import AuthContext from './AuthContext'; // Importiere den AuthContext, um auf den Token zuzugreifen

dayjs.locale('de'); // Setze die Locale auf Deutsch

const Termine = () => {
  const [termine, setTermine] = useState([]);
  const { token } = useContext(AuthContext); // Zugriff auf den Token aus dem AuthContext

  useEffect(() => {
    axios.get('https://doctrina-mobilis.de/api/termine', {
      headers: {
        Authorization: `Bearer ${token}` // Token wird im Authorization Header mitgesendet
      }
    })
      .then(response => {
        if (Array.isArray(response.data)) {
          setTermine(response.data);
        } else {
          console.error('Unerwartetes Format:', response.data);
          setTermine([]);
        }
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Daten:', error);
        setTermine([]);
      });
  }, [token]); // Abhängigkeit von token hinzufügen

  return (
    <aside id="termine" className="termine">
      {Array.isArray(termine) && termine.length > 0 ? (
        termine.map((termin) => {
          const datum = dayjs(termin.datum);
          return (
            <div key={termin.id} className="termin-kachel">
              <div className="datum-bereich">
                <div className="wochentag">{datum.format('dd').toUpperCase()}</div>
                <div className="datum">{datum.format('DD.MM.YYYY')}</div>
                <div className="uhrzeit">{termin.uhrzeit}</div>
              </div>
              <div className="kurs-bereich">
                <div className="kursname">{termin.Kurs ? termin.Kurs.name : 'Kein Kursname'}</div>
                <div className="bezeichnung">{termin.bezeichnung ? termin.bezeichnung : 'Keine Bezeichnung'}</div> {/* Neue Bezeichnung */}
                <div className="ort">{termin.ort}</div>
              </div>
            </div>
          );
        })
      ) : (
        <p>Keine Termine gefunden.</p>
      )}
    </aside>
  );
};

export default Termine;
