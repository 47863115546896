import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);  // Standardmäßig ist kein Benutzer eingeloggt
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setLoading(false);  // Ladeindikator wird gestoppt, wenn ein gespeicherter Benutzer gefunden wird
    } else if (hasToken()) { // Überprüfen, ob ein Token vorhanden ist
      checkUserStatus();  // Überprüfe, ob ein Benutzer eingeloggt ist
    } else {
      setLoading(false); // Wenn kein Token vorhanden ist, beenden Sie das Laden
    }
  }, []);

  const hasToken = () => {
    return !!document.cookie.split('; ').find(row => row.startsWith('token='));
  };

  const checkUserStatus = async () => {
    try {
      const response = await axios.get('https://doctrina-mobilis.de/api/user', {
        withCredentials: true,
      });
      if (response.status === 200) {
        setUser(response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
      } else {
        setUser(null);  // Wenn kein Benutzer gefunden wird, bleibt `user` null
      }
    } catch (error) {
      console.error('Error checking user:', error);
      setUser(null);  // Bei einem Fehler bleibt `user` ebenfalls null
    } finally {
      setLoading(false);  // Ladeindikator wird gestoppt
    }
  };

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const logout = () => {
    return new Promise((resolve, reject) => {
      setUser(null);
      localStorage.removeItem('user');
  
      // Lösche den Token-Cookie
      document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; SameSite=Strict;';
  
      axios.post('https://doctrina-mobilis.de/api/logout', {}, { withCredentials: true })
        .then(() => {
          console.log('Logout successful');
          resolve();  // Erfolgreicher Logout
        })
        .catch(error => {
          console.error('Error during logout:', error);
          reject(error);  // Fehler beim Logout
        });
    });
  };
  
  
  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
