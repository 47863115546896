import React, { useState, useEffect, useContext } from 'react';
import './UserProfile.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { PhotoCamera, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import defaultProfile from './assets/defaultProfile.jpg';

function UserProfile() {
  const { user, logout } = useAuth(); // Use the AuthContext to get the current user and logout function
  const [profilePic, setProfilePic] = useState(defaultProfile);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    oldPassword: ''
  });

  const [editingField, setEditingField] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // If the user is not logged in, redirect to the login page
    if (!user) {
      navigate('/');
      return;
    }

    // Fetch the current user data from the API
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://doctrina-mobilis.de/api/user', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Send the token in the Authorization header
          }
        });

        const data = await response.json();
        if (response.ok) {
          setUserData({
            firstName: data.first_name,
            lastName: data.last_name,
            username: data.username,
            email: data.email,
            phoneNumber: data.phone_number
          });
        } else {
          if (response.status === 403) {
            logout(); // Clear user data if unauthorized
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user, navigate, logout]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleEditClick = (field) => {
    setEditingField({ ...editingField, [field]: true });
  };

  const handleSaveClick = async () => {
    try {
      const response = await fetch('https://doctrina-mobilis.de/api/user', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Send the token in the Authorization header
        },
        body: JSON.stringify(userData)
      });

      if (response.ok) {
        setEditingField({}); // Exit edit mode
      } else {
        console.error('Error updating profile:', await response.json());
      }
    } catch (error) {
      console.error('Error saving profile data:', error);
    }
  };

  const handleProfilePicChange = () => {
    // Implement profile picture change logic
    console.log('Profile picture change initiated');
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="user-profile">
      <h1>Profil verwalten</h1>
      <div className="profile-pic-wrapper">
        <img src={profilePic} alt="Profile" className="profile-pic-large" />
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="profile-pic-upload"
          type="file"
          onChange={handleProfilePicChange}
        />
        <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleProfilePicChange}>
          <PhotoCamera />
        </IconButton>
      </div>
      <form className="profile-form">
        {['firstName', 'lastName', 'username', 'email', 'phoneNumber'].map((field) => (
          <div className="form-group" key={field}>
            <label>{field === 'firstName' ? 'Vorname' : 
                    field === 'lastName' ? 'Nachname' :
                    field === 'username' ? 'Benutzername' :
                    field === 'email' ? 'Email' :
                    'Telefonnummer'}:</label>
            {editingField[field] ? (
              <TextField
                name={field}
                variant="outlined"
                value={userData[field]}
                onChange={handleInputChange}
                className="profile-input"
              />
            ) : (
              <>
                <span>{userData[field]}</span>
                <IconButton onClick={() => handleEditClick(field)}>
                  <EditIcon />
                </IconButton>
              </>
            )}
          </div>
        ))}
        <div className="form-group">
          <label>Altes Passwort:</label>
          <TextField
            name="oldPassword"
            type="password"
            variant="outlined"
            value={userData.oldPassword}
            onChange={handleInputChange}
            className="profile-input"
          />
        </div>
        <div className="form-group">
          <label>Neues Passwort:</label>
          <TextField
            name="password"
            type="password"
            variant="outlined"
            value={userData.password}
            onChange={handleInputChange}
            className="profile-input"
          />
        </div>
        <div className="form-group">
          <label>Neues Passwort bestätigen:</label>
          <TextField
            name="confirmPassword"
            type="password"
            variant="outlined"
            value={userData.confirmPassword}
            onChange={handleInputChange}
            className="profile-input"
          />
        </div>
        <div className="button-group">
          <Button
            variant="contained"
            className="save-button"
            onClick={handleSaveClick}
            startIcon={<SaveIcon />}
          >
            Speichern
          </Button>
          <Button variant="contained" className="cancel-button" onClick={handleCancel}>
            Abbrechen
          </Button>
        </div>
      </form>
    </div>
  );
}

export default UserProfile;
