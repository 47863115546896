import React, { useState, useContext } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AuthContext from '../AuthContext'; // Importiere den AuthContext

function UserLoeschen() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);

  const { token } = useContext(AuthContext); // Zugriff auf das JWT-Token

  // Benutzer werden geladen, wenn das Dropdown geöffnet wird
  const fetchUsers = () => {
    axios.get('/api/getUsers', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      setUsers(response.data);
    })
    .catch(error => {
      console.error('Error fetching users:', error);
    });
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleDeleteUser = () => {
    setOpenDialog(true);
  };

  const handleConfirm = () => {
    axios.post('/api/deleteUser', { user_id: selectedUser }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      setMessage(response.data.message);
      setSelectedUser('');
      setOpenDialog(false);
    })
    .catch(error => {
      setMessage(error.response?.data?.message || 'Ein Fehler ist aufgetreten.');
      setErrorDialog(true);
    });
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="sm" className="verwaltung-container">
      <Accordion className="verwaltung-item">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" className="accordion-header">User löschen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            fullWidth
            value={selectedUser}
            onChange={handleUserChange}
            onOpen={fetchUsers} /* Benutzer werden geladen, wenn Dropdown geöffnet wird */
            displayEmpty
            className="input-field"
            style={{ 
              backgroundColor: 'var(--custom-white)', 
              color: 'var(--text-color)', 
            }}
          >
            <MenuItem value="" disabled>
              User auswählen
            </MenuItem>
            {users.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {user.username} ({user.first_name} {user.last_name})
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            onClick={handleDeleteUser}
            className="accept-btn" style={{ marginTop: '10px',
            height: '48px',
            backgroundColor: 'var(--warning-color)',  // Hintergrundfarbe
            color: 'var(--custom-white)',              // Schriftfarbe
            fontWeight: 'bold'    }}
          >
            Löschen
          </Button>
        </AccordionDetails>
      </Accordion>

      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie den User wirklich löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} style={{ backgroundColor: 'var(--warning-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Abbrechen
          </Button>
          <Button onClick={handleConfirm} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}>
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default UserLoeschen;
