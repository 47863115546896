import React, { useState, useContext } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AuthContext from '../AuthContext';

function User2Kurs() {
  const [users, setUsers] = useState([]);
  const [kurse, setKurse] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedKurs, setSelectedKurs] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const { token } = useContext(AuthContext); // Zugriff auf das JWT-Token

  // Funktion zum Abrufen der Benutzer (User)
  const fetchUsers = () => {
    axios.get('/api/getUsers', {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => setUsers(response.data))
    .catch(error => console.error('Error fetching Users:', error));
  };

  // Funktion zum Abrufen der Kurse
  const fetchKurse = () => {
    axios.get('/api/getKurse', {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => setKurse(response.data))
    .catch(error => console.error('Error fetching Kurse:', error));
  };

  const handleUserChange = (event) => setSelectedUser(event.target.value);
  const handleKursChange = (event) => setSelectedKurs(event.target.value);
  const handleAddUserToKurs = () => setOpenDialog(true);

  const handleConfirm = () => {
    axios.post('/api/addUserToKurs', { kurs_id: selectedKurs, user_id: selectedUser }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      setMessage(response.data.message);
      setSelectedUser('');
      setSelectedKurs('');
      setOpenDialog(false);
    })
    .catch(error => {
      setMessage(error.response?.data?.message || 'Ein Fehler ist aufgetreten.');
      setErrorDialog(true);
    });
  };

  return (
    <Container maxWidth="sm" className="verwaltung-container">
      <Accordion className="verwaltung-item">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" className="accordion-header">Benutzer einem Kurs zuweisen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            fullWidth
            value={selectedUser}
            onChange={handleUserChange}
            onOpen={fetchUsers}
            displayEmpty
            className="input-field"
            style={{ backgroundColor: 'var(--custom-white)', color: 'var(--text-color)' }}
          >
            <MenuItem value="" disabled>
              Benutzer auswählen
            </MenuItem>
            {users.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {user.username} ({user.first_name} {user.last_name})
              </MenuItem>
            ))}
          </Select>

          <Select
            fullWidth
            value={selectedKurs}
            onChange={handleKursChange}
            onOpen={fetchKurse}
            displayEmpty
            className="input-field"
            style={{ backgroundColor: 'var(--custom-white)', color: 'var(--text-color)' }}
          >
            <MenuItem value="" disabled>
              Kurs auswählen
            </MenuItem>
            {kurse.map((kurs) => (
              <MenuItem key={kurs.kurs_id} value={kurs.kurs_id}>
                {kurs.name}
              </MenuItem>
            ))}
          </Select>

          <Button
            variant="contained"
            onClick={handleAddUserToKurs}
            className="accept-btn" style={{ marginTop: '10px',
            height: '48px',
            backgroundColor: 'var(--accent-color)',  // Hintergrundfarbe
            color: 'var(--text-color)',              // Schriftfarbe
            fontWeight: 'bold'    }}
          >
            Hinzufügen
          </Button>
        </AccordionDetails>
      </Accordion>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie den Benutzer dem Kurs zuweisen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} style={{ backgroundColor: 'var(--warning-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Abbrechen
          </Button>
          <Button onClick={handleConfirm} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}>
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default User2Kurs;
