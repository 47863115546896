import React, { useState, useContext } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AuthContext from '../AuthContext'; // Importiere den AuthContext

function KursEntfernen() {
  const [kurse, setKurse] = useState([]);
  const [selectedKurs, setSelectedKurs] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);

  const { token } = useContext(AuthContext); // Zugriff auf das JWT-Token

  // Kurse werden geladen, wenn das Dropdown geöffnet wird
  const fetchKurse = () => {
    axios.get('/api/getKurse', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      setKurse(response.data);
    })
    .catch(error => {
      console.error('Error fetching Kurse:', error);
    });
  };

  const handleKursChange = (event) => {
    setSelectedKurs(event.target.value); // Richtiges Kurs-ID wird hier gesetzt
  };

  const handleRemoveKurs = () => {
    setOpenDialog(true);
  };

  const handleConfirm = () => {
    axios.post('/api/removeKurs', { kurs_id: selectedKurs }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      setMessage(response.data.message);
      setSelectedKurs('');
      setOpenDialog(false);
    })
    .catch(error => {
      setMessage(error.response?.data?.message || 'Ein Fehler ist aufgetreten.');
      setErrorDialog(true);
    });
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="sm" className="verwaltung-container">
      <Accordion className="verwaltung-item">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" className="accordion-header">Kurs entfernen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            fullWidth
            value={selectedKurs}
            onChange={handleKursChange}
            onOpen={fetchKurse}  /* Kurse werden geladen, wenn Dropdown geöffnet wird */
            displayEmpty
            className="input-field"
            style={{ 
              backgroundColor: 'var(--custom-white)', 
              color: 'var(--text-color)', 
            }}
          >
            <MenuItem value="" disabled>
              Kurs auswählen
            </MenuItem>
            {kurse.map((kurs) => (
              <MenuItem key={kurs.kurs_id} value={kurs.kurs_id}>
                {kurs.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            onClick={handleRemoveKurs}
            className="accept-btn" style={{ marginTop: '10px',
            height: '48px',
            backgroundColor: 'var(--accent-color)',  // Hintergrundfarbe
            color: 'var(--text-color)',              // Schriftfarbe
            fontWeight: 'bold'    }}
          >
            Entfernen
          </Button>
        </AccordionDetails>
      </Accordion>

      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie den Kurs wirklich entfernen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} style={{ backgroundColor: 'var(--warning-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Abbrechen
          </Button>
          <Button onClick={handleConfirm} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}>
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default KursEntfernen;
