import React from 'react';
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom'; // Hinzugefügter Import
import './Kurse.css'; // Für individuelle CSS-Anpassungen

function Kurse() {
  const navigate = useNavigate(); // useNavigate Hook verwenden

  return (
    <Container maxWidth="lg" className="kurse-container">
      <Box py={10} textAlign="center">
        <Typography variant="h2" component="h1" gutterBottom>
          Unsere Kurse
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Entdecken Sie unsere modularen Kurse für medizinische Schulungen.
        </Typography>
      </Box>

      <Box className="kurse-list">
        <Accordion className="kurse-item">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6">Echokardiographie</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Dieser Kurs bietet eine umfassende Einführung in die Echokardiographie, einschließlich theoretischer Grundlagen und praktischer Übungen.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => navigate('/Titelseite/Echokardiographie')}
              className="erfahre-mehr-button"
            >
              Erfahre mehr
            </Button>
          </AccordionDetails>
        </Accordion>

        <Accordion className="kurse-item">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography variant="h6">EKG 1</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lernen Sie die Grundlagen der Elektrokardiographie kennen und verstehen Sie die wichtigsten EKG-Befunde.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => navigate('/kurse/ekg1')}
              className="erfahre-mehr-button"
            >
              Erfahre mehr
            </Button>
          </AccordionDetails>
        </Accordion>

        <Accordion className="kurse-item">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography variant="h6">EKG 2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Vertiefen Sie Ihr Wissen über komplexe EKG-Befunde und lernen Sie, sie in der klinischen Praxis anzuwenden.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => navigate('/kurse/ekg2')}
              className="erfahre-mehr-button"
              sx={{ fontWeight: 'bold' }}
            >
              Erfahre mehr
            </Button>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}

export default Kurse;
