import React, { useState, useContext, useEffect } from 'react';
import { Container, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';

function KursTitelseiteFormular() {
  const { kursId } = useParams();
  const [kursName, setKursName] = useState('');
  const [kursleiter, setKursleiter] = useState('');
  const [einleitung, setEinleitung] = useState('');
  const [inhalt, setInhalt] = useState('');
  const [bild, setBild] = useState(null); // Nur der Dateiname, nicht das Bildobjekt
  const [bildName, setBildName] = useState('Kein Bild ausgewählt');
  const [previewImage, setPreviewImage] = useState(null); // For previewing the image
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (kursId) {
      axios.get(`/api/kursTitelseite/${kursId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        const { kurs, titelseite } = response.data;
        console.log("titels: ", titelseite.kursleiter);
        if (titelseite) {
          setKursleiter(titelseite.kursleiter || '');
          setEinleitung(titelseite.einleitung || '');
          setInhalt(titelseite.inhalt || '');
          if (titelseite.titelbild) {
            setBildName(titelseite.titelbild);
            setBild(`/api/get-image/${titelseite.titelbild}`); // Verwende die API, um das Bild zu laden
          }
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Titelseite:', error);
        setLoading(false);
      });

      axios.get(`/api/getKurse`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        const kurs = response.data.find(kurs => kurs.kurs_id === parseInt(kursId, 10));
        setKursName(kurs ? kurs.name : '');
      })
      .catch(error => console.error('Fehler beim Abrufen des Kursnamens:', error));
    }
  }, [kursId, token]);

  const handleEditorChange = (content) => {
    setInhalt(content);
  };

  const handleEinleitungChange = (content) => {
    setEinleitung(content);
  };
  
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('kursleiter', kursleiter);
    formData.append('einleitung', einleitung);
    formData.append('inhalt', inhalt);
  
    // Füge das Bild hinzu, wenn eins ausgewählt wurde
    if (bild) {
      formData.append('titelbild', bild); // Sende die Datei, nicht den Dateinamen
    }
  
    try {
      const response = await axios.post(`/api/kursTitelseite/${kursId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data.message);
      setOpenDialog(true);
    } catch (error) {
      console.error('Fehler beim Speichern der Titelseite:', error);
      setMessage('Ein Fehler ist aufgetreten');
      setOpenDialog(true);
    }
  };
  
  const handleBildChange = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setBild(selectedFile); // Store the file for the backend
  
      // Generate a preview URL for the image
      const imageUrl = URL.createObjectURL(selectedFile);
      setBildName(selectedFile.name);  // Display the file name
      setPreviewImage(imageUrl);  // Display the preview
    }
  };
  
  
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px', fontWeight: 'bold' }}>
        {kursName} Titelseite bearbeiten
      </Typography>

      <TextField
        label="Kursleiter*innen"
        value={kursleiter}
        onChange={(e) => setKursleiter(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{ style: { backgroundColor: 'white', fontWeight: 'bold' } }}
      />

      <Typography variant="h6" gutterBottom>
        Einleitung
      </Typography>
      <Editor
        tinymceScriptSrc='/tinymce/tinymce.min.js'
        value={einleitung}
        init={{
          license_key: 'gpl',
          height: 300,
          menubar: false,
          plugins: [
            'link', 'lists', 'image', 'media', 'mediaembed', 'fontselect', 'fontsizeselect', 'textcolor', 'advlist'
          ], // Plugins für Schriftart, Schriftgröße und Farbe
          toolbar: 
            'undo redo | fontfamily fontsize | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image media',
          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt', // Schriftgrößen
          font_formats: 'Arial=arial,helvetica,sans-serif; Times New Roman=times new roman,times;', // Schriftarten
          images_upload_url: '/api/upload-image',
          automatic_uploads: true,
      
          // Bild-Upload-Handler
          images_upload_handler: (blobInfo, success, failureCallback) => {
            const formData = new FormData();
            formData.append('file', blobInfo.blob());
            axios.post('/api/upload-image', formData, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(response => {
              success(response.data.location);
            })
            .catch(() => {
              failureCallback('Bild konnte nicht hochgeladen werden.');
            });
          },
      
          // Video- und Audio-Upload-Handler
          file_picker_types: 'image media',
          file_picker_callback: function (callback, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            if (meta.filetype === 'image') {
              input.setAttribute('accept', 'image/*');
            } else {
              input.setAttribute('accept', 'audio/*,video/*');
            }
            input.onchange = function () {
              const file = this.files[0];
              const formData = new FormData();
              formData.append('file', file);
      
              axios.post(
                file.type.startsWith('audio') ? '/api/upload-audio' : '/api/upload-video',
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                  },
                }
              )
              .then(response => {
                callback(response.data.location, { title: file.name });
              })
              .catch(() => {
                alert('Datei konnte nicht hochgeladen werden.');
              });
            };
            input.click();
          },
      
          media_live_embeds: true,
          media_dimensions: false,
          media_alt_source: false,
          media_poster: true,
        }}
        onEditorChange={handleEinleitungChange}
      />

      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Kursinhalt
      </Typography>
      <Editor
  tinymceScriptSrc='/tinymce/tinymce.min.js'
  value={inhalt}
  init={{
    license_key: 'gpl',
    height: 300,
    menubar: false,
    plugins: [
      'link', 'lists', 'image', 'media', 'mediaembed', 'fontselect', 'fontsizeselect', 'textcolor', 'advlist'
    ], // Plugins für Schriftart, Schriftgröße und Farbe
    toolbar: 
      'undo redo | fontfamily fontsize | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image media',
    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt', // Schriftgrößen
    font_formats: 'Arial=arial,helvetica,sans-serif; Times New Roman=times new roman,times;', // Schriftarten
    images_upload_url: '/api/upload-image',
    automatic_uploads: true,

    // Bild-Upload-Handler
    images_upload_handler: (blobInfo, success, failureCallback) => {
      const formData = new FormData();
      formData.append('file', blobInfo.blob());
      axios.post('/api/upload-image', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        success(response.data.location);
      })
      .catch(() => {
        failureCallback('Bild konnte nicht hochgeladen werden.');
      });
    },

    // Video- und Audio-Upload-Handler
    file_picker_types: 'image media',
    file_picker_callback: function (callback, value, meta) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      if (meta.filetype === 'image') {
        input.setAttribute('accept', 'image/*');
      } else {
        input.setAttribute('accept', 'audio/*,video/*');
      }
      input.onchange = function () {
        const file = this.files[0];
        const formData = new FormData();
        formData.append('file', file);

        axios.post(
          file.type.startsWith('audio') ? '/api/upload-audio' : '/api/upload-video',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(response => {
          callback(response.data.location, { title: file.name });
        })
        .catch(() => {
          alert('Datei konnte nicht hochgeladen werden.');
        });
      };
      input.click();
    },

    media_live_embeds: true,
    media_dimensions: false,
    media_alt_source: false,
    media_poster: true,
  }}
  onEditorChange={handleEditorChange}
/>






        <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
          <Button variant="contained" component="label" style={{ marginRight: '10px' }}>
            Titelbild hochladen
            <input 
              type="file" 
              hidden 
              onChange={handleBildChange} 
            />
          </Button>
          <Typography>{bildName}</Typography>
        </div>

       {/* Bildvorschau */}
        {previewImage && (
          <div style={{ marginTop: '20px' }}>
            <Typography variant="h6">Vorschau des Titelbilds:</Typography>
            <img src={previewImage} alt="Titelbild" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        )}


      <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={handleSubmit} variant="contained" color="primary" style={{ marginRight: '20px' }}>
          Speichern
        </Button>
        <Button onClick={() => navigate(`/titelseite/${kursId}`)} variant="contained" color="secondary">
          Titelseite
        </Button>
      </div>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Info</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default KursTitelseiteFormular;
