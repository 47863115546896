import React, { useState, useEffect, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Für Navigation verwenden
import AuthContext from './AuthContext';
import './CourseNavigation.css';

const CourseNavigation = ({ toggleSidebar, sidebarOpen }) => {
  const [openCourses, setOpenCourses] = useState({});
  const [kurse, setKurse] = useState([]);
  const { token } = useContext(AuthContext); // Token aus dem AuthContext verwenden
  const navigate = useNavigate(); // Verwende useNavigate für das Routing

  // Funktion, um Kurse und Kapitel des Benutzers oder Admins zu laden
  const fetchKurseUndKapitel = async () => {
    try {
      const response = await axios.get('/api/getEigeneKurseUndKapitel', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKurse(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Kurse:', error);
    }
  };

  useEffect(() => {
    fetchKurseUndKapitel(); // Lade Kurse und Kapitel, wenn die Komponente gerendert wird
  }, []);

  const handleClick = (courseId) => {
    setOpenCourses((prevState) => ({
      ...prevState,
      [courseId]: !prevState[courseId],
    }));
  };

  // Funktion, die aufgerufen wird, wenn auf ein Kapitel geklickt wird
  const handleKapitelClick = (kapitelId) => {
    navigate(`/kapitel/${kapitelId}`); // Navigiere zur Route des Kapitels
  };

  return (
    <div className={`course-navigation ${sidebarOpen ? 'open' : ''}`}>
      <button className="toggle-button" onClick={toggleSidebar}>
        ☰ Kapitel
      </button>
      <List component="nav">
        {kurse.map((kurs) => (
          <React.Fragment key={kurs.kurs_id}>
            <ListItem button onClick={() => handleClick(kurs.kurs_id)}>
              <ListItemText primary={kurs.name} className="chapter-name" />
              {openCourses[kurs.kurs_id] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCourses[kurs.kurs_id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {kurs.kapitel && kurs.kapitel.length > 0 ? (
                  kurs.kapitel.map((kapitel) => (
                    <ListItem
                      button
                      key={kapitel.kapitel_id}
                      className="subchapter-item"
                      onClick={() => handleKapitelClick(kapitel.kapitel_id)} // Auf Kapitel klicken
                    >
                      <ListItemText primary={kapitel.name} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="Keine Kapitel verfügbar" />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default CourseNavigation;
