import React, { useState, useContext } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AuthContext from '../AuthContext';

function DozentIn2KursEntfernen() {
  const [kurse, setKurse] = useState([]);
  const [dozentInnen, setDozentInnen] = useState([]);
  const [selectedKurs, setSelectedKurs] = useState('');
  const [selectedDozentIn, setSelectedDozentIn] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);

  const { token } = useContext(AuthContext); // Zugriff auf das JWT-Token

  // Funktion, um Kurse zu laden
  const fetchKurse = () => {
    axios.get('/api/getKurse', {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => setKurse(response.data))
    .catch(error => console.error('Error fetching Kurse:', error));
  };

  // Funktion, um DozentInnen zu laden, die dem ausgewählten Kurs zugeordnet sind
  const fetchDozentInnenForKurs = (kursId) => {
    axios.get(`/api/getDozentInnenForKurs/${kursId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => setDozentInnen(response.data))
    .catch(error => console.error('Error fetching DozentInnen for Kurs:', error));
  };
  

  // Handler für die Kursauswahl
  const handleKursChange = (event) => {
    const kursId = event.target.value;
    setSelectedKurs(kursId);
    fetchDozentInnenForKurs(kursId); // Lädt die DozentInnen für den ausgewählten Kurs
  };

  // Handler für die Auswahl der DozentIn
  const handleDozentInChange = (event) => setSelectedDozentIn(event.target.value);

  // Öffnet den Bestätigungsdialog
  const handleRemoveDozentInFromKurs = () => setOpenDialog(true);

  // Bestätigung zum Entfernen der DozentIn aus dem Kurs
  const handleConfirm = () => {
    axios.post('/api/removeDozentInFromKurs', { kurs_id: selectedKurs, user_id: selectedDozentIn }, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      setMessage(response.data.message);
      setSelectedKurs('');
      setSelectedDozentIn('');
      setOpenDialog(false);
    })
    .catch(error => {
      setMessage(error.response?.data?.message || 'Ein Fehler ist aufgetreten.');
      setErrorDialog(true);
    });
  };

  return (
    <Container maxWidth="sm" className="verwaltung-container">
      <Accordion className="verwaltung-item">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" className="accordion-header">Dozent*in aus einem Kurs entfernen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Dropdown zur Auswahl des Kurses */}
          <Select
            fullWidth
            value={selectedKurs}
            onChange={handleKursChange}
            onOpen={fetchKurse} // Kurse werden beim Öffnen des Dropdowns geladen
            displayEmpty
            className="input-field"
            style={{ backgroundColor: 'var(--custom-white)', color: 'var(--text-color)' }}
          >
            <MenuItem value="" disabled>Kurs auswählen</MenuItem>
            {kurse.map((kurs) => (
              <MenuItem key={kurs.kurs_id} value={kurs.kurs_id}>{kurs.name}</MenuItem>
            ))}
          </Select>

          {/* Dropdown zur Auswahl der DozentIn (wird aktiviert, nachdem ein Kurs ausgewählt wurde) */}
          <Select
            fullWidth
            value={selectedDozentIn}
            onChange={handleDozentInChange}
            displayEmpty
            className="input-field"
            disabled={!selectedKurs} // Deaktiviert, bis ein Kurs ausgewählt wurde
            style={{ backgroundColor: selectedKurs ? 'var(--custom-white)' : 'lightgrey', color: 'var(--text-color)' }}
          >
            <MenuItem value="" disabled>Dozent*in auswählen</MenuItem>
            {dozentInnen.map((dozentIn) => (
              <MenuItem key={dozentIn.user_id} value={dozentIn.user_id}>
                {dozentIn.username} ({dozentIn.first_name} {dozentIn.last_name})
              </MenuItem>
            ))}
          </Select>

          {/* Button zum Entfernen der DozentIn */}
          <Button
            variant="contained"
            onClick={handleRemoveDozentInFromKurs}
            className="accept-btn"
            disabled={!selectedDozentIn} // Deaktiviert, bis eine DozentIn ausgewählt wurde
          >
            Entfernen
          </Button>
        </AccordionDetails>
      </Accordion>

      {/* Bestätigungsdialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie die/den Dozent*in wirklich aus dem Kurs entfernen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} style={{ backgroundColor: 'var(--warning-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Abbrechen
          </Button>
          <Button onClick={handleConfirm} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Fehlerdialog */}
      <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}>
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default DozentIn2KursEntfernen;
