import React, { useState, useEffect, useContext } from 'react';
import './MainPage.css';
import CourseNavigation from './CourseNavigation';
import BottomBar from './BottomBar';
import Termine from './Termine';
import UserProfile from './UserProfile';
import Register from './Register';
import AdminPage from './AdminPage'; // Import der AdminPage-Komponente
import DozentInPage from './DozentInPage';
import UserPage from './UserPage';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import axios from 'axios';

function MainPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDozentIn, setIsDozentIn] = useState(false); // State für DozentIn
  const { user } = useContext(AuthContext);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    const mainContent = document.querySelector('.main-content');
    if (sidebarOpen) {
      mainContent.classList.remove('shifted');
    } else {
      mainContent.classList.add('shifted');
    }
  };

  useEffect(() => {
    if (user) {
      // Prüfe, ob der Benutzer Admin ist
      axios.get('/api/checkAdmin')
        .then(response => {
          setIsAdmin(response.data.isAdmin);
        })
        .catch(error => {
          console.error('Error checking admin status:', error);
        });

      // Prüfe, ob der Benutzer DozentIn ist
      axios.get('/api/checkDozentIn')
        .then(response => {
          setIsDozentIn(response.data.isDozentIn);
        })
        .catch(error => {
          console.error('Error checking DozentIn status:', error);
        });
    }
  }, [user]);

  // Wenn der Benutzer ein DozentIn ist, wird auf die DozentInPage weitergeleitet


  return (
    <div id="container" className={sidebarOpen ? 'sidebar-open' : ''}>
      <nav>
        <CourseNavigation toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
      </nav>
      <main className={`main-content ${sidebarOpen ? 'shifted' : ''}`}>
      <Routes>
        {/* Wenn der Benutzer Admin ist, wird die AdminPage als Startseite verwendet */}
        <Route path="/" element={isAdmin ? <AdminPage /> : isDozentIn ? <DozentInPage /> : <UserPage />} />
        
        {/* Weitere Routen für das Profil und die Registrierung */}
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/register" element={<Register />} />

        
        {/* Platzhalter für untergeordnete Routen */}
        <Route path="/*" element={<Outlet />} /> 
      </Routes>

      </main>
      <aside id="termine">
        <h2>Termine</h2>
        <Termine />
      </aside>
      <BottomBar toggleSidebar={toggleSidebar} /> {/* Include BottomBar and pass the toggle function */}
    </div>
  );
}

export default MainPage;
