import React from 'react';
import { Button, Container, Typography, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css'; // Für individuelle CSS-Anpassungen
import './colors.css';

function LandingPage() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" className="landing-container">
      {/* Hero Section */}
      <Box className="hero-section" py={10} textAlign="center">
        <div className="hero-content">
          <Typography variant="h2" component="h1" gutterBottom>
            Modulare Kursplattform für medizinische Schulungen
          </Typography>
          <Typography variant="h5" component="p" gutterBottom>
            Erstellen Sie interaktive und multimediale Online-Kurse für medizinische Fachkräfte und Studierende.
          </Typography>
          <Button variant="contained" color="primary" size="large" onClick={() => navigate('/register')}>
            Jetzt Registrieren
          </Button>
          <Button variant="contained" size="large" onClick={() => navigate('/more-info')} sx={{ marginLeft: 2, backgroundColor: 'var(--accent-color)', color: 'var(--text-color)' }}>
              Mehr erfahren
            </Button>
        </div>
      </Box>

      {/* Überblick über die Plattform */}
      <Box py={10} className="section"> {/* Vertikaler Abstand vergrößert */}
        <Typography variant="h4" gutterBottom>
          Warum Doctrina mobilis?
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <Box className="content-box accent-box">
              <Typography variant="h6">Für Dozierende</Typography>
              <Typography>Einfaches Erstellen und Verwalten von Kursen mit multimedialen Inhalten.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="content-box accent-box">
              <Typography variant="h6">Für Lernende</Typography>
              <Typography>Progressive Lernpfade und effektive Selbstkontrolle durch Quizzes.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="content-box accent-box">
              <Typography variant="h6">Modularität</Typography>
              <Typography>Flexibles Kursdesign, das an die Bedürfnisse angepasst werden kann.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Funktionen der Plattform */}
      <Box py={10} bgcolor="var(--primary-color)" color="var(--custom-white)" sx={{ padding: '20px', borderRadius: '8px' }} className="section">
        <Typography variant="h4" gutterBottom>
          Funktionen der Plattform
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Box className="content-box primary-box">
              <Typography variant="h6">Multimediale Inhalte</Typography>
              <Typography>Integrieren Sie Videos, Audiodateien, Bilder und interaktive Elemente in Ihre Kurse.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="content-box primary-box">
              <Typography variant="h6">Lernstandserhebung</Typography>
              <Typography>Nutzen Sie effektive Quizzes zur Selbstkontrolle der Lernenden.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="content-box primary-box">
              <Typography variant="h6">Benutzerfreundliches Interface</Typography>
              <Typography>Unsere Plattform ist intuitiv und leicht zu bedienen.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="content-box primary-box">
              <Typography variant="h6">Sicherheit</Typography>
              <Typography>Wir gewährleisten eine datenschutzkonforme Verwaltung der Nutzerdaten.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Footer */}
      <Box py={3} textAlign="center" className="footer">
        <Typography variant="body2" color="textSecondary">
          © 2024 Doctrina mobilis. Alle Rechte vorbehalten.
        </Typography>
      </Box>
    </Container>
  );
}

export default LandingPage;
