import React, { useState } from 'react';
import { Container, Typography, Box, Tabs, Tab, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FAQ.css'; // Für individuelle CSS-Anpassungen

function FAQ() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Box py={10} textAlign="center">
        <Typography variant="h2" component="h1" gutterBottom>
          Häufig gestellte Fragen (FAQ)
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Hier finden Sie Antworten auf häufig gestellte Fragen zu unserer Plattform.
        </Typography>
      </Box>

      {/* Tab Auswahl für Nutzer*in und Dozent*in */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        centered
        className="faq-tabs"
        TabIndicatorProps={{ style: { backgroundColor: 'var(--primary-color)' } }}
      >
        <Tab label="Nutzer*in" className={selectedTab === 0 ? 'active-tab' : ''} />
        <Tab label="Dozent*in" className={selectedTab === 1 ? 'active-tab' : ''} />
      </Tabs>

      {/* FAQ Inhalte */}
      <Box className="faq-section">
        {selectedTab === 0 && (
          <div className="faq-list">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Accordion className="faq-item">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h6">Wie melde ich mich an?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Um sich anzumelden, klicken Sie auf die Schaltfläche "Login" oben rechts auf der Seite.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion className="faq-item">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h6">Wie ändere ich mein Passwort?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Gehen Sie zu Ihrem Profil und wählen Sie "Passwort ändern".
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {/* Weitere Dummy-Fragen */}
              <Grid item xs={12}>
                <Accordion className="faq-item">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                    <Typography variant="h6">Wie kann ich mein Profil bearbeiten?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Um Ihr Profil zu bearbeiten, gehen Sie auf die Profilseite und klicken Sie auf "Profil bearbeiten".
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion className="faq-item">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                    <Typography variant="h6">Wie melde ich mich von der Plattform ab?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Um sich abzumelden, klicken Sie auf "Logout" im Profilmenü.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </div>
        )}

        {selectedTab === 1 && (
          <div className="faq-list">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Accordion className="faq-item">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h6">Wie erstelle ich einen Kurs?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Um einen Kurs zu erstellen, gehen Sie zu Ihrem Dozenten-Dashboard und wählen Sie "Kurs erstellen".
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion className="faq-item">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h6">Wie kann ich Kursinhalte hochladen?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Nachdem Sie einen Kurs erstellt haben, können Sie im Abschnitt "Inhalte" verschiedene Medientypen hochladen.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {/* Weitere Dummy-Fragen */}
              <Grid item xs={12}>
                <Accordion className="faq-item">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                    <Typography variant="h6">Wie kann ich Teilnehmer zu meinem Kurs hinzufügen?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Um Teilnehmer hinzuzufügen, gehen Sie zu Ihrem Kurs und wählen Sie "Teilnehmer hinzufügen".
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion className="faq-item">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                    <Typography variant="h6">Wie ändere ich die Kursstruktur?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Gehen Sie zu Ihrem Kurs und wählen Sie "Struktur bearbeiten", um die Module und Lektionen anzupassen.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </div>
        )}
      </Box>
    </Container>
  );
}

export default FAQ;
