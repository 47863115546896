import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AuthContext from '../AuthContext';

function KontaktAnfragen() {
  const [kontaktanfragen, setKontaktanfragen] = useState([]);
  const [bearbeiteteAnzeigen, setBearbeiteteAnzeigen] = useState(false);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    fetchKontaktanfragen();
  }, [bearbeiteteAnzeigen]);

  const fetchKontaktanfragen = () => {
    axios.get('/api/kontaktanfragen', {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      setKontaktanfragen(response.data);
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Kontaktanfragen:', error);
    });
  };

  const handleBearbeitetChange = async (id, checked) => {
    try {
      await axios.put(`/api/kontaktanfragen/${id}`, { bearbeitet: checked }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchKontaktanfragen();
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Anfrage:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Accordion>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />} 
          sx={{ 
            backgroundColor: 'var(--primary-color)', 
            color: 'var(--custom-white)',
          }}
        >
          <Typography variant="h6" sx={{ color: 'var(--custom-white)' }}>
            Kontaktanfragen
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControlLabel
            control={
              <Checkbox
                checked={bearbeiteteAnzeigen}
                onChange={() => setBearbeiteteAnzeigen(!bearbeiteteAnzeigen)}
                color="secondary"
              />
            }
            label="Bearbeitete Anfragen anzeigen"
          />
          {kontaktanfragen
            .filter(anfrage => bearbeiteteAnzeigen || !anfrage.bearbeitet)
            .map(anfrage => (
              <Accordion key={anfrage.id} sx={{ backgroundColor: 'var(--accent-color) !important', color: 'var(--text-color)' }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />} 
                  sx={{
                    backgroundColor: 'var(--accent-color) !important',
                    color: 'var(--text-color)',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: 'var(--accent-color) !important',
                      color: 'var(--text-color)',
                    }
                  }}
                >
                  <Typography variant="body1" sx={{ color: 'var(--text-color) !important', fontWeight: 'bold' }}>
                    {anfrage.vorname} {anfrage.nachname} ({anfrage.email}) -  Gesendet am {new Date(anfrage.created_at).toLocaleDateString()} um {new Date(anfrage.created_at).toLocaleTimeString()}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails 
                    sx={{
                        color: 'var(--text-color) !important', 
                        fontWeight: 'bold',
                        backgroundColor: 'var(--accent-color) !important',
                        '&:hover': {
                        backgroundColor: 'var(--accent-color) !important',  // Set the hover background color
                        }
                    }}
                >
                <Typography variant="body2" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                    {anfrage.nachricht}
                </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={anfrage.bearbeitet}
                        onChange={(e) => handleBearbeitetChange(anfrage.id, e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Bearbeitet"
                  />
                </AccordionDetails>
              </Accordion>
            ))}
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default KontaktAnfragen;
