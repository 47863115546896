import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import logo from './assets/Logo128.png';
import defaultProfile from './assets/defaultProfile.jpg';
import './Header.css';
import Login from './Login';

function Header() {
  const { user, logout } = useAuth();
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const navigate = useNavigate();

  // Öffnet das Menü für das Profil
  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  // Schließt das Menü
  const handleMenuClose = () => {
    setProfileAnchorEl(null);
  };

  // Navigiert zur Profilseite
  const handleProfile = () => {
    navigate('/profile');
    handleMenuClose();
  };

  // Funktion für das Logout und Navigation zur Startseite
  const handleLogout = () => {
    logout().then(() => {
      navigate('/');  // Nach erfolgreichem Logout zur Startseite navigieren
    }).catch(error => {
      console.error('Logout failed:', error);  // Fehlerbehandlung
    });
  };

  const isLoggedIn = !!user;  // Prüft, ob ein Benutzer eingeloggt ist

  return (
    <AppBar position="static">
      <Toolbar className="toolbar">
        <div className="upper-header">
          <div className="left-section">
            <img src={logo} alt="Website Logo" className="logo-image" />
            <Typography 
              variant="h4" 
              className="title" 
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer' }}
            >
              Doctrina mobilis
            </Typography>
          </div>
          <div className="center-section">
            <Button color="inherit" onClick={() => navigate('/kurse')}>Kurse</Button>
            <Button color="inherit" onClick={() => navigate('/faq')}>FAQ</Button>
            <Button color="inherit" onClick={() => navigate('/kontakt')}>Kontakt</Button>
          </div>
          <div className="right-section user-section">
            {isLoggedIn ? (
              <>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <img src={defaultProfile} alt="Profile" className="profile-pic" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={profileAnchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(profileAnchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleProfile}>Profil verwalten</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <Login />  // Login-Formular wird direkt im Header angezeigt
            )}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
