import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box } from '@mui/material';
import CourseNavigation from '../CourseNavigation'; // Sidebar importieren
import BottomBar from '../BottomBar'; // Optional: BottomBar importieren
import Termine from '../Termine'; // Optional: Termine importieren
import AuthContext from '../AuthContext';

function KapitelPage() {
  const { kapitelId } = useParams(); // Get the chapter ID from the URL
  const [kapitel, setKapitel] = useState({ name: '', text: '' }); // Chapter name and content
  const [error, setError] = useState(null); // Error state
  const { user } = useContext(AuthContext); // Access the user from AuthContext
  const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar state
  const navigate = useNavigate(); // For navigating to the edit page

  // Fetch chapter data based on kapitelId
  useEffect(() => {
    if (kapitelId) {
      axios
        .get(`/api/singleKapitel/${kapitelId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then((response) => {
          const { name, text } = response.data;
          setKapitel({ name, text });
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen des Kapitels:', error);
          setError('Kapitel oder Inhalt nicht gefunden.');
        });
    } else {
      setError('Keine Kapitel-ID vorhanden.');
    }
  }, [kapitelId, user.token]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    const mainContent = document.querySelector('.main-content');
    if (sidebarOpen) {
      mainContent.classList.remove('shifted');
    } else {
      mainContent.classList.add('shifted');
    }
  };

  if (error) {
    return <div>{error}</div>; // Display error if something goes wrong
  }

  if (!kapitel.name) return <div>Lade...</div>;

  return (
    <div id="container" className={sidebarOpen ? 'sidebar-open' : ''}>
      <nav>
        {/* Sidebar für Kurse und Kapitel */}
        <CourseNavigation toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
      </nav>
      <main className={`main-content ${sidebarOpen ? 'shifted' : ''}`}>
        <Container>
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              marginTop: '20px',
              fontWeight: 'bold',
              fontSize: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' }, // Responsive sizes
            }}
          >
            {kapitel.name}
          </Typography>

          {/* Chapter Text */}
          <Box
            sx={{
              marginBottom: '20px',
              padding: '10px',
              backgroundColor: 'var(--accent-color)',
            }}
            dangerouslySetInnerHTML={{ __html: kapitel.text }}
          />
        </Container>
      </main>

      {/* Optional: Termine oder andere Komponenten */}
      <aside id="termine">
        <h2>Termine</h2>
        <Termine />
      </aside>
      
      <BottomBar toggleSidebar={toggleSidebar} /> {/* Optional: BottomBar */}
    </div>
  );
}

export default KapitelPage;
