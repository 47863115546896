import React, { useState } from 'react';
import './Register.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

function Register() {
  const [profilePic, setProfilePic] = useState(null);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: ''
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    let tempErrors = {};
    tempErrors.firstName = userData.firstName ? "" : "Vorname ist erforderlich.";
    tempErrors.lastName = userData.lastName ? "" : "Nachname ist erforderlich.";
    tempErrors.username = userData.username ? "" : "Benutzername ist erforderlich.";
    tempErrors.email = (/^$|.+@.+..+/).test(userData.email) ? "" : "Ungültige Email-Adresse.";
    tempErrors.password = userData.password.length >= 6 ? "" : "Passwort muss mindestens 6 Zeichen lang sein.";
    tempErrors.confirmPassword = userData.confirmPassword === userData.password ? "" : "Passwörter stimmen nicht überein.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSave = async () => {
    if (validate()) {
      try {
        const response = await fetch('https://doctrina-mobilis.de/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userData)
        });

        const data = await response.json();
        if (response.ok) {
          navigate('/'); // Nach erfolgreicher Registrierung zum Login weiterleiten
        } else {
          alert(data.message || 'Ein Fehler ist aufgetreten.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Ein Fehler ist aufgetreten.');
      }
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="user-profile">
      <h1>Registrieren</h1>
      
      <form className="profile-form">
        <div className="form-group">
          <label>Vorname*:</label>
          <TextField
            name="firstName"
            variant="outlined"
            value={userData.firstName}
            onChange={handleInputChange}
            className="profile-input"
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
        </div>
        <div className="form-group">
          <label>Nachname*:</label>
          <TextField
            name="lastName"
            variant="outlined"
            value={userData.lastName}
            onChange={handleInputChange}
            className="profile-input"
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </div>
        <div className="form-group">
          <label>Benutzername*:</label>
          <TextField
            name="username"
            variant="outlined"
            value={userData.username}
            onChange={handleInputChange}
            className="profile-input"
            error={!!errors.username}
            helperText={errors.username}
          />
        </div>
        <div className="form-group">
          <label>Email*:</label>
          <TextField
            name="email"
            type="email"
            variant="outlined"
            value={userData.email}
            onChange={handleInputChange}
            className="profile-input"
            error={!!errors.email}
            helperText={errors.email}
          />
        </div>
        <div className="form-group">
          <label>Passwort*:</label>
          <TextField
            name="password"
            type="password"
            variant="outlined"
            value={userData.password}
            onChange={handleInputChange}
            className="profile-input"
            error={!!errors.password}
            helperText={errors.password}
          />
        </div>
        <div className="form-group">
          <label>Passwort bestätigen*:</label>
          <TextField
            name="confirmPassword"
            type="password"
            variant="outlined"
            value={userData.confirmPassword}
            onChange={handleInputChange}
            className="profile-input"
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </div>
        <div className="form-group">
          <label>Telefonnummer:</label>
          <TextField
            name="phoneNumber"
            variant="outlined"
            value={userData.phoneNumber}
            onChange={handleInputChange}
            className="profile-input"
          />
        </div>
        <div className="button-group">
          <Button variant="contained" className="save-button" onClick={handleSave}>
            Registrieren
          </Button>
          <Button variant="contained" className="cancel-button" onClick={handleCancel}>
            Abbrechen
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Register;
